<template src="./ShoppingStatusCard.html"></template>

<script>
export default {
  name: "ShoppingStatusCard",
  data() {
    return {
    };
  },
  props:{
    shopping:{
      type: Object
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped src="./ShoppingStatusCard.scss"></style>