<template src="./EmailVerify.html"></template>
<script>
import { sendVerifyEmail } from "../../../services";

export default {
  name: "emailVerify",
  props: {
    show: Boolean,
    title: String,
    message: String,
    userId: String,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async verify() {
      try {
        await sendVerifyEmail(this.userId);
        this.$emit("close");
      } catch (error) {
        console.log("ERROR send verify email", error);
        this.$store.dispatch("fetchModal", {
          title: "Ocurrió un error, intenta mas tarde.",
        });
      }
    },
  },
};
</script>

