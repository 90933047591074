<template src="./Footer.html"></template>

<script>
import { termsAndConditions } from '../../services';

export default {
  name: "footer",
  data() {
    return {
      year: new Date().getFullYear(),
      link1: '',
      link2: '',
      link3: '',
      link4: '',
    };
  },
  async created() {
    const { files, passwordPolicy } = await termsAndConditions();
    files.forEach((link) => {
        if(link.id === 1) this.link1 = link.url;
        if(link.id === 2) this.link2 = link.url;
        if(link.id === 3) this.link3 = link.url;
        if(link.id === 4) this.link4 = link.url;
    });
    this.policy = passwordPolicy;
  },
};
</script>

<style lang="scss" scoped src="./Footer.scss"></style>