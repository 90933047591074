<template src="./AccountData.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  getAccountTypes,
  getBanks,
  getProfile,
  updateAccountData,
} from "../../services";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "accountData",
  props: {
    account: {
      type: Boolean,
      default: true,
    },
    bank: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newUserData: { ...this.$store.getters.getUser },
      loading: false,
      showPersonalInfo: true,
      editPaymentInfo: true,
    };
  },
  validations() {
    if (this.bank && this.account) {
      return {
        newUserData: {
          documentType: { required },
          documentNumber: { required },
          BankAccount: {
            accountType: { required },
            accountNumber: { required },
            bank: { required },
          },
        },
        validationPersonalInfo: [
          "newUserData.documentType",
          "newUserData.documentNumber",
        ],
        validationPaymentInfo: [
          "newUserData.BankAccount.accountType",
          "newUserData.BankAccount.accountNumber",
          "newUserData.BankAccount.bank",
        ],
      };
    }

    if (this.account && !this.bank) {
      return {
        newUserData: {
          documentType: { required },
          documentNumber: { required },
        },
        validationPersonalInfo: [
          "newUserData.documentType",
          "newUserData.documentNumber",
        ],
      };
    }

    if (this.bank && !this.account) {
      return {
        newUserData: {
          BankAccount: {
            accountType: { required },
            accountNumber: { required },
            bank: { required },
          },
        },
        validationPaymentInfo: [
          "newUserData.BankAccount.accountType",
          "newUserData.BankAccount.accountNumber",
          "newUserData.BankAccount.bank",
        ],
      };
    }
  },
  created() {
    this.getAccountTypes();
    this.getBanks();

    if (!this.newUserData.BankAccount) {
      this.newUserData.BankAccount = {
        accountType: undefined,
        bank: undefined,
        accountNumber: undefined,
      };
    }
  
    this.showPersonalInfo =
      !this.user.documentType || this.user.documentNumber ? true : false;

    this.editPaymentInfo =
      this.user.BankAccount &&
      (!this.user.BankAccount.accountType ||
        this.user.BankAccount.accountNumber ||
        this.user.BankAccount.bank)
        ? true
        : false;
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      documentType: "getDocumentType",
      banks: "getBanks",
      accountTypes: "getAccountTypes",
    }),
  },
  methods: {
    ...mapActions({
      setBanks: "setBanks",
      setAccountTypes: "setAccountTypes",
      setUser: "fetchUser",
    }),
    async getAccountTypes() {
      try {
        const result = await getAccountTypes();
        this.setAccountTypes(result);
      } catch (e) {
        console.log(e);
      }
    },
    async getBanks() {
      try {
        const result = await getBanks();
        this.setBanks(result);
      } catch (e) {
        console.log(e);
      }
    },
    async updateUserData() {
      try {
        this.loading = true;
        const {
          BankAccount: { accountType, accountNumber, bank },
          documentType,
          documentNumber,
        } = this.newUserData;

        await updateAccountData({
          accountType,
          accountNumber,
          bank,
          documentType,
          documentNumber,
        });
        this.loading = false;
        const user = await getProfile(this.user.userId);
        this.setUser(user);
        this.newUserData = { ...this.$store.getters.getUser };

        if (!this.newUserData.BankAccount) {
          this.newUserData.BankAccount = {
            accountType: undefined,
            bank: undefined,
            accountNumber: undefined,
          };
        }

        this.showPersonalInfo =
          !this.user.documentType || this.user.documentNumber ? true : false;

        this.editPaymentInfo =
          this.user.BankAccount &&
          (!this.user.BankAccount.accountType ||
            this.user.BankAccount.accountNumber ||
            this.user.BankAccount.bank)
            ? true
            : false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./AccountData.scss"></style>
