import axios from '../plugins/axios';

async function getAccountTypes (){
    try {
        const accountTypes = await axios.get(`/accountTypes`);
        return accountTypes.data;
    } catch (error) {
        console.log(error)
    }
}
async function getBanks(){
    try {
        const banks = await axios.get(`/banks`);
        return banks.data;
    } catch (error) {
        console.log(error)
    }
}
async function updateAccountData (accountData){
    try {
        await axios.post("/user/bank-account", accountData);
    } catch (error) {
        console.log(error)
    }
}

export {
    getAccountTypes,
    getBanks,
    updateAccountData
}



