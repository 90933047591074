<template src="./Packages.html"></template>

<script>
import { Package } from "../../../components";
import { listPackages } from "../../../services";
import { mapGetters } from "vuex";

export default {
  name: "packages",
  components: { Package },
  data() {
    return {
      packages: [],
    };
  },
  async created() {
    this.loadPackages();
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    async loadPackages() {
      try {
        this.packages = await listPackages();
      } catch (error) {
        console.log("error loadPackages", error);
        this.$store.dispatch("fetchModal", {
          title: "Ocurrió un error, intenta mas tarde.",
        });
      }
    },
  },
};
</script>