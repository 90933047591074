export default {
    state: {
        user: null
    },
    getters: {
        getUser: state => state.user,
    },
    mutations: {
        setUser(state, value) {
            state.user = value;
        }
    },
    actions: {
        fetchUser({ commit }, user) {
            if (user) {
                commit('setUser', user);
            } else {
                commit('setUser', null);
            }
        }
    }
};
