<template src="./Product.html"></template>

<script>
export default {
  name: "Product",
  props: {
    product: Object,
  },
  data() {
    return {
      selected: false,
    };
  },
  methods: {
    async addCart(product) {
      this.$store.dispatch("removeCheckOut", product);
      if(!this.selected) this.$store.dispatch("addCheckOut", product);
      else this.$store.dispatch("removeCheckOut", product);

      this.selected = !this.selected;
    },
  },
};
</script>

<style lang="scss" scoped src="./Product.scss"></style>