<template src="./Hero.html"></template>

<script>
export default {
  name: "hero",
  data() {
    return {
      youtubeId: "WpiYy8D8QEE",
      youtubeOptions: {
        autoplay: 1,
        controls: 0,
        loop: 1,
        autohide: 1,
        modestbranding: 1,
        cc_load_policy: 0,
        playlist: "WpiYy8D8QEE",
        rel: 0,
      },
    };
  },
};
</script>

<style lang="scss" scoped src="./Hero.scss"></style>