<template src="./Support.html"></template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { supportMail } from '../../services';

export default {
  name: "support",
  data() {
    return {
      support: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  validations: {
    support: {
      name: { required },
      email: { email, required },
      message: { required },
    },
  },
  methods: {
    async sendInfo(support) {
      await supportMail(this.support);
      this.$store.dispatch("fetchModal", {
        title: "El mensaje ha sido enviado.",
      });
      this.support = {
        name: "",
        email: "",
        message: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped src="./Support.scss"></style>