import axios from 'axios';
import store from '../store';

const instance = axios.create({
    baseURL: process.env.VUE_APP_URLAPI
});

let numLoadings = 0;
const requestHandler = (request) => {
    numLoadings++;
    store.dispatch('fetchLoading', true);
    return request;
};
const responseHandler = (response) => {
    if ((--numLoadings) === 0) {
        store.dispatch('fetchLoading', false);
    }
    return response;
};
const errorHandler = (error) => {
    if (!(--numLoadings)) {
        store.dispatch('fetchLoading', false);
    }
    return error;
};

instance.interceptors.request.use(request => requestHandler(request));
instance.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error),
);

export default instance;