<template src="./Store.html"></template>

<script>
import { listProducts } from "../../../services";
import { Product } from "../../../components";

export default {
  name: "store",
  components: { Product },
  data() {
    return {
      products: [],
    };
  },
  created() {
    this.getProducts();
  },
  computed: {
    ifSesion: function () {
      return localStorage.getItem("auth") ? "#/retirar-billetes" : "#/login";
    },
  },
  methods: {
    async getProducts() {
      this.products = await listProducts();
    },
  },
};
</script>
