export default {
    state: {
        loading: false,
        modal: false,
    },
    getters: {
        getLoading: state => state.loading,
        getModal: state => state.modal,
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value;
            if (!value) {
                state.percentage = 0;
            }
        },
        setModal(state, value) {
            state.modal = value;
        }
    },
    actions: {
        fetchLoading({ commit }, loading) {
            commit('setLoading', loading);
        },
        fetchModal({ commit }, modal) {
            commit('setModal', modal);
        }
    }
};