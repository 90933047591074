<script>
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import slide1 from "../../assets/img/bills.png";
// import slide2 from "../../assets/img/bills.png";
import slide3 from "../../assets/img/bills.png";

export default {
  name: "slider",
  props: {
    items: Array
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1,
      },
      image: "",
      images: [slide1, slide3],
      isActive: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiperInstance;
    },
  },
};
</script>

<template src="./Slider.html"></template>
<style lang="scss" scoped src="./Slider.scss"></style>
