<template src="./WithdrawBills.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import CheckCustom from "../../components/CheckCustom/CheckCustom";
import AccountData from "../../components/AccountData/AccountData";
import { requestCashOut, getProfile } from "@/services";

export default {
  name: "ShopCart",
  data() {
    return {
      canPay: false,
      total: 0,
    };
  },
  components: {
    CheckCustom,
    AccountData,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      cashOuts: "getCashOuts",
    }),
    canContiune: function () {
      return (
        !this.user?.BankAccount?.accountType ||
        !this.user?.BankAccount?.accountNumber ||
        !this.user?.BankAccount?.bank
      );
    },
    getTotal: function () {
      return this.cashOuts.reduce((total, item) => {
        total += item.bills;
        return total;
      }, 0);
    },
  },
  methods: {
    ...mapActions({
      setUser: "fetchUser",
    }),
    async pay(total) {
      try {
        if (this.user.bills < total) {
          this.$store.dispatch("fetchModal", {
            title: "No tienes los suficientes Billetes Versus para esta compra.",
            message: `Tienes ${this.$options.filters.kFormatter(
              this.user.bills
            )} Billetes Versus y necesitas ${this.$options.filters.kFormatter(
              total
            )} Billetes Versus`,
          });
        } else {
          for await (const cashout of this.cashOuts) {
            await requestCashOut(cashout.bills);
          }
          const user = await getProfile(this.user.userId);
          await this.setUser(user);

          this.$store.dispatch("fetchModal", {
            title: "Tu retiro está en progreso.",
            message: `Solicitaste ${this.cashOuts.length} retiro${
              this.cashOuts.length === 1 ? "" : "s"
            } de un total de ${this.$options.filters.currency(
              this.cashOuts.reduce(
                (total, cashOut) => (total += cashOut.money),
                0
              )
            )} USD.`,
          });
          this.$router.push("/");
        }
      } catch (error) {
        console.log("Error on cash out request", error);
        this.$store.dispatch("fetchModal", {
          title: "Ocurrió un error, comunicate con nosotros.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./WithdrawBills.scss"></style>
