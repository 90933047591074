<template src="./CashOut.html"></template>

<script>
export default {
  name: "cashOut",
  props: {
    cashOut: Object
  },
  data() {
    return {
      selected: false
    };
  },
  methods: {
    async addCashOut(product) {
      this.$store.dispatch("removeCashOut", product)
      if(!this.selected) this.$store.dispatch("addCashOut", product);
      else this.$store.dispatch("removeCashOut", product);

      this.selected = !this.selected;
    },
  }
};
</script>
<style lang="scss" scoped src="./CashOut.scss"></style>
