export default {
    state: {
        accountTypes:[],
        banks: [],
        documentType:[
            {
                text: "Cédula ciudadanía",
                value: 1
            },
            {
                text: "Pasaporte",
                value: 2
            },
            {
                text: "Cédula extranjería",
                value: 3
            },
            {
                text: "Documento identificación extranjero",
                value: 4
            }
        ]
    },
    getters: {
        getAccountTypes: state => state.accountTypes,
        getBanks: state => state.banks,
        getDocumentType: state => state.documentType
    },
    mutations: {
        accountTypes(state, value) {
            state.accountTypes = value;
        },
        banks(state, value) {
            state.banks = value;
        }
    },
    actions: {
        setAccountTypes({ commit }, accountTypes) {
            commit('accountTypes', accountTypes);
        },
        setBanks({ commit }, banks) {
            commit('banks', banks);
        },
    }
};
