import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import User from './modules/User';
import App from './modules/App';
import CheckOut from './modules/CheckOut';
import CashOut from './modules/CashOut';
import AccountData from "./modules/AccountData";
import Packages from "./modules/Packages";

Vue.use(Vuex);

const persistenceData = createPersistedState({
  paths: ['CheckOut.items']
})

export default new Vuex.Store({
  modules: {
    User,
    App,
    CheckOut,
    AccountData,
    Packages,
    CashOut,
  },
  plugins: [persistenceData],
})
