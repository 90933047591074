export default {
    state: {
        cashOuts: []
    },
    getters: {
        getCashOuts: state => state.cashOuts
    },
    mutations: {
        addCashOutItem(state, value) {
            state.cashOuts.push(value);
        },
        removeCashOutItem(state, value) {
            state.cashOuts = state.cashOuts.filter(item => item.id !== value.id);
        },
    },
    actions: {
        addCashOut({ commit }, cashOut) {
            commit('addCashOutItem', cashOut);
        },
        removeCashOut({ commit }, cashOut) {
            commit('removeCashOutItem', cashOut);
        },
    }
};