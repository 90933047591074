import {
    register,
    login,
    loginGoogle,
    loginFacebook,
    resetPassword,
    logOff,
} from "../services";

const user = {
    methods: {
        firebaseErrors(error) {
            switch (error.code) {
                case "auth/wrong-password":
                    this.user.password = "";
                    this.$store.dispatch("fetchModal", { title: "Contraseña incorrecta" });
                    break;
                case "auth/user-not-found":
                    this.$store.dispatch("fetchModal", { title: `El usuario ${this.user.email} no existe.` });
                    break;
                case "auth/invalid-email":
                    this.$store.dispatch("fetchModal", { title: `El correo ${this.user.email} no está registrado.` });
                    break;
                case "auth/account-exists-with-different-credential":
                    console.log("No tienes vinculada la cuenta");
                    this.$store.dispatch("fetchModal", { title: 'No tienes vinculada la cuenta' });
                    break;
                case "auth/popup-closed-by-user":
                    break;
                default:
                    console.log(
                        `Ocurrió un error con codigo: ${error.code} - ${error.message}`
                    );
            }
        },
        async createUser(user) {
            if (this.$v.$invalid) {
                return false;
            } else {
                try {
                    const registerUser = await register(user);
                    if (registerUser.error) {
                        this.$store.dispatch("fetchModal", { title: registerUser.message });
                        return;
                    }
                    await this.signIn({ email: user.email, password: user.password })
                } catch (error) {
                    console.log('error register', error);
                    this.$store.dispatch("fetchModal", { title: "Esta cuenta con este correo ya había sido creada anteriormente. Intenta iniciar sesión con este correo" });
                }
            }
        },
        async signIn(user) {
            if (this.$v.$invalid) {
                return false;
            } else {
                try {
                    await login(user.email, user.password);
                } catch (error) {
                    console.log('error signIn', error);
                    this.firebaseErrors(error)
                }
            }
        },
        async signInGoogle() {
            try {
                await loginGoogle();
            } catch (error) {
                this.firebaseErrors(error);
            }
        },
        async signInFacebook() {
            try {
                await loginFacebook();
            } catch (error) {
                this.firebaseErrors(error);
            }
        },
        async forgetPassword(email) {
            try {
                await resetPassword(email);
                this.$store.dispatch("fetchModal", { title: "Revisa tu correo y sigue las instrucciones." });
            } catch (error) {
                console.log('error forget', error);
                this.$store.dispatch("fetchModal", { title: "Ocurrió un error, intenta mas tarde." });
            }
        },
        closeEmailVerify() {
            this.modal.show = false;
        },
    }
}

export default user;