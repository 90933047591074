<template src="./Layout.html"></template>

<script>
import { Header, Footer, Modal } from "../components";

export default {
  name: "Layout",
  components: {
    Header, Footer, Modal,
  },
};
</script>
