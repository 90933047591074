<template src="./Header.html"></template>

<script>
import { mapGetters } from "vuex";
import { logOff } from "../../services";
import CheckOut from "../CheckOut/CheckOut";

export default {
  name: "Header",
  components: { CheckOut },
  data() {
    return {
      showNav: false,
    };
  },
  mounted() {
    const menu = document.getElementById("navbarBurger");
    const burger = document.getElementById("navbarBurgerButton");
    const links = menu.querySelectorAll(".navbar-item");
    const _this = this;
  
    links.forEach((link) => {
      link.addEventListener("click", function () {
        burger.classList.remove("is-active");
        menu.classList.remove("is-active");
        _this.showNav = !_this.showNav;
      });
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    isActive: function () {
      return (path) => ({ "is-active": window.location.pathname === path });
    },
  },
  methods: {
    async logOut() {
      await logOff();
    },
  },
};
</script>
<style src="./Header.scss" lang="scss" scoped></style>
