<template src="./AboutUs.html"></template>

<script>
import { Package } from "../../components";
import { listPackages } from "../../services";

export default {
  name: "AboutUs",
  components: { Package },
  data() {
    return {
      packages: [],
    };
  },
  async created() {
    this.loadPackages();
  },
  methods: {
    async loadPackages() {
      try {
        this.packages = await listPackages();
      } catch (error) {
        console.log("error loadPackages", error);
        this.$store.dispatch("fetchModal", {
          title: "Ocurrió un error, intenta mas tarde.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./AboutUs.scss"></style>
