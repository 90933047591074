export default {
    state: {
        items: []
    },
    getters: {
        getItems: state => state.items
    },
    mutations: {
        addItem(state, value) {
            if (!value.shopQuantity) value.shopQuantity = 1;
            state.items.push(value);
        },
        removeItem(state, value) {
            state.items = state.items.filter(item => item.id !== value.id);
        },
        increment (state, value) {
            const item = state.items.find(item => item.id === value.id);
            item.shopQuantity++;
        },
        decrement (state, value) {
            const item = state.items.find(item => item.id === value.id);
            item.shopQuantity--;
        },
    },
    actions: {
        addCheckOut({ commit }, item) {
            commit('addItem', item);
        },
        removeCheckOut({ commit }, item) {
            commit('removeItem', item);
        },
        incrementItem({ commit }, item) {
            commit('increment', item);
        },
        decrementItem({ commit }, item) {
            commit('decrement', item);
        },
    }
};