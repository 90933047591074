import Vue from 'vue';

Vue.filter('kFormatter', function (num) {
    if (Math.abs(num) > 999999) {
        return Math.abs(num) > 999999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M' : Math.sign(num) * Math.abs(num);
    } else {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num);
    }    
});

Vue.filter('currency', function (num) {
    return "$ " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.");
})

Vue.prototype.$filters = Vue.options.filters;
