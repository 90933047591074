export default {
  state: {
      packageFare: {}
  },
  getters: {
      getPackageFare: state => state.packageFare,
  },
  mutations: {
      setPackageFare(state, value) {
          state.packageFare = value;
      },
  },
  actions: {
      fethPackageFare({ commit }, packageFare) {
          commit('setPackageFare', packageFare);
      },
  }
};