<template src="./ShopCart.html"></template>

<script>
const WidgetCheckout = window.WidgetCheckout;
import CheckCustom from "../../components/CheckCustom/CheckCustom";
import AccountData from "@/components/AccountData/AccountData";
import { mapGetters, mapActions } from "vuex";
import {
  getProfile,
  paymentApp,
  logOff,
  paymentPlanBills,
} from "../../services";

export default {
  name: "ShopCart",
  components: {
    CheckCustom,
    AccountData,
  },
  data() {
    return {
      canPay: false,
      payFromApp: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      fare: "getPackageFare",
    }),
    canContiune: function () {
      return (
        (this.user && !this.user.documentType) || !this.user.documentNumber
      );
    },
  },
  async mounted() {
    const { id, code } = this.$route.query;
    const fare = this.$route.query.package;

    if (id && fare) {
      this.payFromApp = true;
      await logOff(true);
      await this.getPaymentData(id, code, fare);
    }
  },
  methods: {
    ...mapActions({
      setUser: "fetchUser",
    }),
    async getPaymentData(userId, code, fare) {
      const paymentData = await paymentApp({ userId, code, fare });

      if (paymentData && paymentData.statusCode === 404) {
        this.$store.dispatch("fetchModal", {
          title: paymentData.message,
        });
      } else if (paymentData && paymentData.statusCode === 400) {
        this.$store.dispatch("fetchModal", {
          title: "Ocurrió un error, intenta mas tarde.",
        });
      }

      this.$store.dispatch("fetchUser", paymentData.user);
      this.$store.dispatch("fethPackageFare", {
        plan: paymentData.plan.toLowerCase(),
        priceBase: paymentData.priceBase,
        total: paymentData.total,
        code: paymentData.code,
        bills: false,
        packageName: paymentData.packageName,
        hasReferal: false,
      });
      this.canPay = true;
    },
    pay() {
      if (!this.fare.bills) {
        this.wompi();
      } else {
        this.bills();
      }
    },
    wompi() {
      const _this = this;
      const amountCents =
        this.fare &&
        this.fare.total &&
        this.fare.total.toFixed(2).replace(".", "");

      let reference = `${new Date().getTime()}*${this.fare.plan}*${
        this.user.userId
      }`;
      
      if (this.fare.code) reference += `*${this.fare.code}`;
      const redirectUrl = `${window.location.origin}/#confirmacion`;

      const data = {
        currency: "COP",
        amountInCents: amountCents,
        reference,
        publicKey: process.env.VUE_APP_wompi,
        redirectUrl,
      };

      const checkout = new WidgetCheckout(data);
      checkout.open(async function (result) {
        const transaction = result.transaction;
        const user = await getProfile(_this.user.userId);
        _this.setUser(user);

        _this.$router.push({
          name: "Response",
          query: { id: transaction.id },
        });
      });
    },
    async bills() {
      try {
        const paymentBills = await paymentPlanBills(
          this.fare.plan.toUpperCase()
        );
        if (paymentBills.error) {
          this.$store.dispatch("fetchModal", {
            title: paymentBills.message,
          });
          return;
        }
        const user = await getProfile(this.user.userId);
        await this.setUser(user);
        this.$router.push("/");
      } catch (error) {
        console.log("ERROR payment with bills", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./ShopCart.scss"></style>
