<template src="./Profile.html"></template>

<script>
import CheckCustom from "../../components/CheckCustom/CheckCustom";
import AccountData from "@/components/AccountData/AccountData";
import { mapGetters } from "vuex";
import { updateProfile, referalCode } from "../../services";

export default {
  name: "Profile",
  data() {
    return {
      disabledName: true,
      disabledEmail: true,
      disabledPassword: true,
      disabledPaymentType: true,
      disabledPaymentDate: true,
      disabledPaymentMethod: true,
      code: "CODIGO-NO-VALIDO",
      typeCode: "password",
    };
  },
  components: {
    CheckCustom,
    AccountData,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    documentType: function () {
      return (value) => {
        switch (value) {
          case 1:
            return "Cédula de Ciudadania";
          case 2:
            return "Pasaporte";
          case 3:
            return "Cédula de Extranjeria";
        }
      };
    },
  },
  methods: {
    edit(disabled, param) {
      this.update(param);
      this[disabled] = true;
    },
    async update(updateParam) {
      try {
        await updateProfile(this.user.userId, updateParam);
      } catch (error) {
        console.log("Ocurrió un error", error);
      }
    },
    async generateCode() {
      try {
        const response = await referalCode();
        if (response.code) {
          this.code = response.code;
          this.typeCode = "text";
        } else {
          this.$store.dispatch("fetchModal", {
            title: response.message || "Ocurrió un error, intenta mas tarde.",
          });
        }
      } catch (error) {
        console.log("ERROR referalCode", error);
        this.$store.dispatch("fetchModal", {
          title: "Ocurrió un error, intenta mas tarde.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
