<template>
  <Layout></Layout>
</template>

<script>
import Layout from "./layout/Layout";
import "@/assets/scss/bulma.scss"; // Bulma
import "@/assets/scss/base.scss"; // Base

export default {
  name: "App",
  components: {
    Layout,
  },
};
</script>
