<template src="./Modal.html"></template>
<script>
import { mapGetters } from "vuex";
import { sendVerifyEmail } from "../../services";

export default {
  name: "modal",
  computed: {
    ...mapGetters({
      modal: "getModal",
    }),
  },
  methods: {
    close() {
      this.$store.dispatch("fetchModal", false);
    },
    async verify() {
      try {
        await sendVerifyEmail(this.modal.userId);
        this.close();
      } catch (error) {
        console.log("ERROR send verify email", error);
        this.$store.dispatch("fetchModal", {
          title: "Ocurrió un error, intenta mas tarde.",
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./Modal.scss"></style>