import axios from '../plugins/axios';

const getProfile = userId => axios.get(`/profile/${userId}`).then(res => res.data).catch(err => err && err.response ? err.response.data : err);
const updateProfile = (userId, user) => axios.patch(`/profile/${userId}`, user).then(res => res.data).catch(err => err.response.data);
const register = user => {
    user.claims = { Public: true };
    return axios.post("/users", user).then(res => {
        return res.data || res.response.data;
    })
}
const termsAndConditions = () => axios.get(`/terms`).then(res => res.data).catch(err => err.response.data);
const resetPassword = (email) => axios.post(`/user/send-password-reset/${email}`);
const sendVerifyEmail = (uid) => axios.post(`/user/send-verify-email/${uid}`);
const referalCode = () => axios.post('/user/referrals/code').then(res => res.data || res.response.data);

export {
    getProfile,
    updateProfile,
    register,
    termsAndConditions,
    resetPassword,
    sendVerifyEmail,
    referalCode,
};