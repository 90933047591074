<template src="./Package.html"></template>

<script>
import CheckCustom from "../CheckCustom/CheckCustom";
import { validateCode } from "../../services";

export default {
  name: "package",
  components: {
    CheckCustom,
  },
  props: {
    fare: Object,
    buy: Boolean,
    hasReferal: Boolean,
    isInfo: Boolean
  },
  data() {
    return {
      withBills: false,
      code: "",
      isCode: false,
    };
  },
  methods: {
    async isValidCode(code) {
      try {
        const isValid = await validateCode(code);
        if (isValid.statusCode === 404) {
          this.$store.dispatch("fetchModal", {
            title: !isValid ? "El correo no es válido." : isValid.message,
          });
          this.code = "";
          return;
        } else {
          this.isCode = true;
        }
      } catch (error) {
        console.log("error isValidCode", error);
        this.$store.dispatch("fetchModal", {
          title: "Recuerda completar tus datos personales para poder continuar con la compra.",
        });
      }
    },
    goToPay() {
      this.isValidCode(this.code);

      if (localStorage.getItem("auth")) {
        let total = !this.isCode
          ? this.fare.fareWithoutCode.total
          : this.fare.fareWithCode.total;

        if (this.hasReferal) total = this.fare.fareWithCode.total;

        this.$store.dispatch("fethPackageFare", {
          plan: this.fare.plan.toLowerCase(),
          priceBase: this.fare.fareWithoutCode.total,
          total: !this.withBills ? total : this.fare.fareWithoutCode.billsPrice,
          code: this.code || null,
          bills: this.withBills,
          packageName: !this.code
            ? this.fare.fareWithoutCode.webStore
            : this.fare.fareWithCode.webStore,
          hasReferal: this.hasReferal,
        });

        this.$router.push("/carro-de-compra");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./Package.scss"></style>