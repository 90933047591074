<template src="./Login.html"></template>
<script>
import user from "../../mixins/user";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "login",
  mixins: [user],
  data() {
    return {
      forget: false,
      type: "password",
      user: {
        email: "",
        password: "",
      },
      modal: {},
    };
  },
  validations() {
    if (!this.forget) {
      return {
        user: {
          email: { email, required },
          password: { required, minLength: minLength(6) },
        },
      };
    } else {
      return {
        user: {
          email: { email, required },
        },
      };
    }
  },
};
</script>
<style src="./Login.scss" lang="scss" scoped></style>
