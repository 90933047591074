<template src="./Response.html"></template>

<script>
import { getWompiTransaction } from '../../../services';

export default {
  name: "response",
  data() {
    return {
      transaction: {},
    };
  },
  mounted() {
    const uri = window.location.search.substring(1); 
    const params = new URLSearchParams(uri);
    let id = params.get("id");

    if(!id) id = this.$route.query.id;
    if(id) this.getWompiTransaction(id);
  },
  methods: {
    async getWompiTransaction(id) {
      try {
        this.transaction = await getWompiTransaction(id);
      } catch (error) {
        console.log("ERROR Wompi", error);
      }
    },
  },
};
</script>
