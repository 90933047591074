import firebase from "@/plugins/firebase";
import store from "@/store";

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const auth = firebase.auth();

const login = (email, password) => auth.signInWithEmailAndPassword(email, password);
const loginGoogle = () => auth.signInWithPopup(googleProvider);
const loginFacebook = () => auth.signInWithPopup(facebookProvider);
const forget = email => auth.sendPasswordResetEmail(email);
const logOff = async (fromApp = false) => {
    store.dispatch("fetchUser", null);
    localStorage.removeItem("auth");
    await auth.signOut();
    if(!fromApp) window.location.hash = '#/';
}
const getProviders = email => auth.fetchSignInMethodsForEmail(email);

export {
    login,
    loginGoogle,
    loginFacebook,
    forget,
    logOff,
    getProviders,
};