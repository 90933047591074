
import axios from '../plugins/axios';

const listPackages = () => axios.get('/payments/fares').then(res => res.data);
const validateCode = (code) => axios.get(`/user/referrals/code/${code}`).then(res => res.data || res.response.data);
const supportMail = (support) => axios.post('/support', support);
const getWompiTransaction = id => axios.get(`/wompi/transaction/${id}`).then(res => res.data || res.response.data);
const paymentPlanBills = (plan) => axios.post('/payments/bills', { fare: plan }).then(res => res.data || res.response.data);
const paymentApp = (payment) => axios.post('/payments/wompi/manual', payment).then(res => res.data || res.response.data);


export {
  listPackages,
  validateCode,
  supportMail,
  getWompiTransaction,
  paymentApp,
  paymentPlanBills,
};

