import Vue from 'vue'
import Vuelidate from 'vuelidate';
import VueAnalytics from 'vue-analytics';
import VueYouTubeEmbed from 'vue-youtube-embed';
import App from './App.vue'
import router from './router'
import store from './store'
import './filters';

Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(VueAnalytics, {
  id: 'UA-182985796-1',
  router,
  autoTracking: {
    exception: true
  }
});
Vue.use(VueYouTubeEmbed);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
