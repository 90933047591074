import Vue from 'vue'
import VueRouter from 'vue-router'
import { Home, Login, Profile, AboutUs, ShopCart, withdrawBills, Register, Shop, Packages, Store, CashOuts, Support, ShoppingStatus, Response, Shares, } from '../views';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/registro',
    name: 'Register',
    component: Register,
  },
  {
    path: '/tienda',
    name: 'Shop',
    component: Shop,
    redirect: '/tienda/paquetes',
    children: [
      { path: 'paquetes', component: Packages },
      { path: 'productos', component: Store },
      { path: 'retiros', component: CashOuts }
    ]
  },
  {
    path: '/perfil',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/carro-de-compra',
    name: 'ShopCart',
    component: ShopCart,
  },
  {
    path: '/retirar-billetes',
    name: 'WithdrawBills',
    component: withdrawBills
  },
  {
    path: '/quienes-somos',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/soporte',
    name: 'Support',
    component: Support
  },
  {
    path: '/estado-de-compras',
    name: 'ShoppingStatus',
    component: ShoppingStatus
  },
  {
    path: '/confirmacion',
    name: 'Response',
    component: Response
  },
  {
    path: '/programa-fundadores',
    name: 'Shares',
    component: Shares
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach(function (to, from, next) {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
  next();
});

export default router
