<template src="./CheckOut.html"></template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "checkOut",
  props: {
    auth: Boolean,
  },
  computed: {
    ...mapGetters({
      items: "getItems",
    }),
    ifSesion() {
      return localStorage.getItem("auth") ? "#/carro-de-compra" : "#/login";
    },
  },
  methods: {
    incrementItem(item) {
      this.$store.dispatch("incrementItem", item);
    },
    decrementItem(item) {
      this.$store.dispatch("decrementItem", item);
    },
    removeProduct(product) {
      this.$store.dispatch("removeCheckOut", product);
    },
  },
};
</script>

<style lang="scss" scoped src="./CheckOut.scss"></style>