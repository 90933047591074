<template src="./Home.html"></template>

<script>
import { Hero, Slider } from "../../components";
import { listProducts } from "../../services";

export default {
  name: "Home",
  data() {
    return {
      products: [],
    };
  },
  components: {
    Hero,
    Slider,
  },
  created() {
    this.getProducts();
  },
  computed: {
    ifSesion: function(){
      return localStorage.getItem("auth") ? '#/tienda/paquetes' : '#/registro';
    },
  },
  methods: {
    async getProducts() {
      try {
        this.products = await listProducts(true);
      } catch (error) {
        console.log("ERROR getProducts", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./Home.scss"></style>

