<template src="./CheckCustom.html"></template>

<script>
export default {
  name: "checkCustom",
  props: ['value'],
  data() {
    return {
      id: null
    };
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>

<style lang="scss" scoped src="./CheckCustom.scss"></style>