import { render, staticRenderFns } from "./Shares.html?vue&type=template&id=f117ef02&scoped=true&"
var script = {}
import style0 from "./Shares.scss?vue&type=style&index=0&id=f117ef02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f117ef02",
  null
  
)

export default component.exports