<template src="./CashOuts.html"></template>

<script>
import { CashOut } from "../../../components";
import { listCashOuts } from "../../../services";

export default {
  name: "cashOuts",
  components: { CashOut },
  data(){
    return {
      cashOuts: []
    }
  },
  created() {
    this.getCashOuts();
  },
  computed: {
    ifSesion: function () {
      return localStorage.getItem("auth") ? "#/retirar-billetes" : "#/login";
    },
  },
  methods: {
    async getCashOuts() {
      try {
        this.cashOuts = await listCashOuts();
      } catch (error) {
        console.log('Dont load cashOuts', error)
      }
    }
  },
};
</script>