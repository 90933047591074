import firebase from "firebase";
import store from "@/store";
import axios from "@/plugins/axios";
import { getProfile, logOff } from "../services";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    databaseURL: process.env.VUE_APP_databaseURL,
    projectId: process.env.VUE_APP_projectId,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId,
    measurementId: process.env.VUE_APP_measurementId
};

firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = 'es';

firebase.auth().onAuthStateChanged(async user => {
    if (user) {
        const { token, claims } = await user.getIdTokenResult();
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        if (!claims.Public) {
            await logOff();
            user.delete(); // Borro el usuario si no tiene permiso
            store.dispatch("fetchModal", { title: 'El usuario no esta registrado.' });
            return;
        }
        if (!user.emailVerified) {
            store.dispatch("fetchModal", {
                title: "Verificar la cuenta",
                message: "Por favor ingresa a tu correo y acepta la verificación de tu cuenta Versus.",
                emailVerify: true,
                userId: user.uid,
            });
            await logOff();
            return;
        }

        const userActive = await getProfile(user.uid);
        if (!userActive) {
            await logOff();
            return window.location.hash = '#/login';
        }

        userActive.photo = user.photoURL || null;
        store.dispatch("fetchUser", userActive);
        localStorage.setItem("auth", true);

        if (window.location.hash === '#/login' && user.emailVerified) window.location.hash = '#/';

        setInterval(async () => {
            const token = await user.getIdToken(true);
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }, 3000000);
    } else {
        store.dispatch("fetchUser", null);
        localStorage.removeItem("auth");
    }
});

export default firebase;
