<template src="./ShoppingStatus.html"></template>

<script>
import ShoppingStatusCard from "@/components/ShoppingStatusCard/ShoppingStatusCard";

export default {
  name: "ShoppingStatus",
  data() {
    return {
      shoppingData:[]
    };
  },
  components: {
    ShoppingStatusCard
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped src="./ShoppingStatus.scss"></style>