<template src="./Register.html"></template>

<script>
import user from "../../mixins/user";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { termsAndConditions } from "../../services";

export default {
  name: "register",
  mixins: [user],
  data() {
    return {
      type: "password",
      repeatPasswordType: "password",
      repeatPassword: "",
      user: {
        displayName: "",
        email: "",
        password: "",
      },
      terms: null,
      auth: null,
      termsLink: "",
      policy: "",
      link1: "",
      link2: "",
    };
  },
  validations: {
    user: {
      displayName: { required },
      email: { email, required },
      password: {
        required,
        minLength: minLength(6),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
      },
    },
    repeatPassword: {
      sameAsPassword: sameAs(function() { return this.user.password })
    },
    terms: {
      checked: (value) => value === true,
    },
    auth: {
      checked: (value) => value === true,
    },
  },
  async created() {
    const { files, passwordPolicy } = await termsAndConditions();
    files.forEach((link) => {
        if(link.id === 1) this.link1 = link.url;
        if(link.id === 2) this.link2 = link.url;
    });
    this.policy = passwordPolicy;
  },
  
  
};
</script>
<style lang="scss" scoped src="./Register.scss"></style>
